body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #FAFAFA;
}

.grey {
    background: #FAFAFA;
}

.hero {
    color: #424242;
    background: url(https://www.autodialog.com/assets/images/Background.png);
    height: 738px;
    background-size: cover;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.sidebar {
    width: 260px;
    max-width: 260px;
    height: 100vh;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #FFFFFF;
    position: fixed;
}

.sidebar-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-item-content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
}

.sidebar-item-icon {
    margin-right: 6px;
}

.sidebar-item-text {
    width: 100%;
}

.sidebar-item-expand-arrow {
    font-size: 1.2rem !important;
}

.sidebar-item-expand-arrow-expanded {
    color: #008265;
    font-weight: bold;
}

*:focus {
    outline: none !important;
}

.logo {
    max-width: 200px;
    max-height: 200px;
    margin-top: 80px;
}

.logout-button {
    z-index: 99999 !important;
}

.main-container {
    margin-left: 260px;
}

@media screen and (max-width: 1024px) {
    .sidebar {
        width: 175px;
    }

    .main-container {
        margin-left: 175px;
        
    }
}

#buttons{
    display: flex;
    justify-content: space-between;
}


@media screen and (max-width: 600px) {
    #edit-paper{
       margin: 0%;
       padding: 0%;
    }
}

