.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.centered-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.offcentered-loader {
    position: fixed;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
}


[class*="MuiSvgIcon-root-"] {
    color: #53B3EA !important;
}

.disabled [class*="MuiDropzoneArea-icon"] {
    color: gray !important;
}

.MuiPaper-root-381,
.MTableHeader-header-520,
.MuiPaper-root-383,
.MuiTableCell-root-529 {
    background-color: white !important;
}

.MuiButton-outlinedPrimary {
    border: 1px solid rgba(83, 179, 234, 0.5);
}

.MuiFormLabel-asterisk,
.color-red {
    color: #dc3545 !important;
}

.white-date>div {
    height: 37px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
}

.white-date>label {
    color: black;
}

.white-date input {
    padding-left: 7px;
}

.white-date .MuiInput-underline:before {
    border-bottom: none;
}

.MuiDialog-root .MuiDialog-paper {
    background: #FAFAFA
}

[class*="MuiPaper-elevation2-"] {
    box-shadow: none !important;
}

[class*="MuiPaper-root-"],
[class*="MTableHeader-header-"] {
    background-color: transparent !important;
}

.colorpicker-alpha {
    display: none !important;
}

.flexbox-fix>div:first {
    display: none !important;
}

.hhYXTS .muicc-colorpicker-button {
    margin: 6px 6px 6px 0px !important;
}

[class*="MuiMenu-list-"] {
    background: white !important;
}


.rounded-with-border-field {
    margin: auto !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-radius: 4px !important;
}

.ant-picker-dropdown {
    z-index: 10000;
}

.modal-header {
    border-bottom: none;
}

.modal-footer {
    border-top: none;
}

.validation-red {
    color: #C4455B;
}

.helper-text-red p {
    color: #C4455B;
}

.helper-text-grey p {
    color: rgba(0, 0, 0, 0.54);
}

a.MuiButton-containedPrimary:hover {
    color: white !important;
}

.MuiTypography-body1 {
    width: 100%;
    text-align: left;
}

a.MuiButton-containedPrimary:hover {
    color: white !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #53B3EA !important;
}

.MuiOutlinedInput-input {
    padding: 10.5px 14px !important;
}

.MuiAccordion-rounded {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.color-card {
    border-radius: 5px;
    height: 20px;
    width: 20px;
    margin-left: 7px;
}

.MuiInputBase-input {
    background: white !important;
}

.MuiSelect-select {
    padding: 9px 14px !important;
}

.MuiOutlinedInput-input {
    padding: 9px 14px;
}

.fadeOut {
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;
}

.fadeIn {
    opacity: 1;
    transition: opacity 0.5s;
}

.fadeOutPartially {
    opacity: 0.3;
    transition: opacity 0.25s;
    pointer-events: none;
}

.main-dropdown {
    float: left;
    display: flex;
    width: 330px;
}

.hhYXTS .muicc-colorpicker-button {
    margin: 9px 9px 9px 0px !important;
    width: 20px !important;
    min-width: 20px !important;
    height: 20px !important;
}

.MuiSelect-iconOutlined {
    background-color: white !important;
}

.dropzone {
    width: 200px !important;
    min-height: 150px !important;
    height: 150px !important;
    font-family: 'Roboto, Helvetica, Arial, sans-serif';
    font-size: 16px;
    display: block;
    color: #53B3EA;
}

.dropzone.large {
    width: 350px !important;
}

.dropzone-hidden {
    display: none;
}

.dropzone.large.disabled,
.dropzone.disabled {
    pointer-events: none;
    cursor: pointer;
    color: gray;
}

.dropzone-p {
    font-family: 'Roboto, Helvetica, Arial, sans-serif';
    font-size: 16px !important;   
}

.small-text {
  font-size: 12px !important;
}

[class*="MuiDropzonePreviewList-removeButton-"] {
    top: 25px !important;
}

.pointer {
    cursor: pointer !important;
}

.disabled-hover,
.disabled-hover a:hover {
    text-decoration: none !important;
    cursor: default !important;

}

.color-red {
    color: #C4455B !important;
}

.color-grey {
    color: rgba(0, 0, 0, 0.54) !important;
}

.profile {
    position: relative;
    width: 100%;
}

.profile-image {
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
}
.profile-image-disabled {
    opacity: 1;
    display: block;
}

.delete-image {
    transition: .5s ease;
    opacity: 0;
    -ms-transform: translate(-50%, -50%);
    text-align: right;
    border: none;
    background-color: white;
}

.profile:hover .profile-image {
    opacity: 0.3;
    cursor: normal;
}

.profile:hover .delete-image {
    opacity: 1;
}

.delete-image:hover {
    cursor: pointer;
}

.profile i.fas {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0px 0px 2px #53B3EA;
    background-color: white;
    padding: 0.5em 0.6em;
    color: #53B3EA
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.muicc-colorpicker-button {
    margin: 6px;
    margin-left: 0px !important;
}

input::-ms-clear {
    width:  0!important;
    height:  0!important;
}

#delete-rates:hover {
    background-color: white;
    border-color: #53B3EA;
}

#delete-rates {
    height: 40px;
}

#save-rates {
    height: 40px;
}

.bounce-in {
    animation: bounce-in 400ms;
}

@keyframes bounce-in {
    0% {
        opacity: 0;
        transform: scale(0.3);
    }
    70% {
        opacity: 1;
        transform: scale(1.03);
    }
    100% {
        transform: scale(1);
    }
}

.MuiInputAdornment-root p{
    height: 22px;
}
.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedStart.MuiOutlinedInput-inputAdornedStart
{
    padding-left: 0px!important;
}

.action-white-button,
.action-white-button:focus,
.action-white-button :hover,
.action-white-button :disabled {
    background-color: white !important;
    color: #53B3EA !important;
    border-color: #53B3EA !important;
    min-width: 100px !important;
}

.action-blue-button,
.action-blue-button:focus,
.action-blue-button :hover,
.action-blue-button :disabled {
    background-color: rgb(83, 179, 234) !important;
    color: white !important;
    border-color: rgb(83, 179, 234) !important;
    min-width: 100px !important;
}
.rodal{
    z-index: 1500;
}

.rodal-height-fit-content>.rodal-dialog {
    height: fit-content !important;
}